import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppStateInterface } from '../../../types/app-state.interface';
import { Cart, CartLine } from '../../../types/cart.model';
import { PunchOutSubmit } from '../../../types/punchout.model';
import { ApiService } from '../api/api.service';
import { BaseSubscriptionComponent } from 'src/app/shared/components/base-subscription/base-subscription.component';
import { takeUntil } from 'rxjs';
import { OrderDetail } from '../../../modules/order/order-detail.model';

@Injectable({
  providedIn: 'root',
})
export class PunchoutService extends BaseSubscriptionComponent {
  sessionId: string;
  returnUrl: string;
  cart: Cart;
  quickListHeaderId: number | undefined;

  constructor(private store: Store<AppStateInterface>, private api: ApiService) {
    super();
    this.store
      .select('cart')
      .pipe(takeUntil(this.destroyed))
      .subscribe((cart) => {
        this.cart = cart.cart as Cart;
      });
    this.store
      .select('punchout')
      .pipe(takeUntil(this.destroyed))
      .subscribe((data) => {
        this.returnUrl = data?.punchout?.returnUrl || '';
        this.sessionId = data?.punchout?.sessionId || '';
      });
    this.store
      .select('user')
      .pipe(takeUntil(this.destroyed))
      .subscribe((data) => {
        const user = data?.user || null;
        this.quickListHeaderId =
          user?.currentShoppingAccount?.quickListHeader?.id || data?.shoppingContext?.quickListHeader?.id;
      });
  }

  checkout() {
    if (this.cart?.cartItems.length) {
      let postData: PunchOutSubmit = {
        SessionId: this.sessionId,
        returnUrl: this.returnUrl,
        Timestamp: this.formatDate(),
        CartTotal: this.cart.subTotal,
        CartId: this.cart.cartId,
        CartProduct: [],
      };
      this.cart.cartItems.forEach((item, i) => {
        const personalCode =
          item.personalCodes && this.quickListHeaderId && item.personalCodes[this.quickListHeaderId]
            ? item.personalCodes[this.quickListHeaderId]
            : item.manufacturerPartNo || item.itemId;
        postData.CartProduct!.push({
          Line: i + 1,
          PartNumber: item.itemId,
          Description: item.title,
          Qty: item.qty,
          NetSalePrice: item.price,
          UOMExt: item.uom,
          UNSPSC: item.unspsc,
          ManufacturerName: item.manufacturer,
          ManufacturerPartNumber: item.manufacturerPartNo,
          PersonalCode: personalCode,
          LeadTime: item.leadTime,
        });
      });
      return postData;
    }
    return;
  }

  convertQuote(quote: OrderDetail, products: CartLine[] = []) {
    // TODO - Find out how to pass quote.customerPurchaseOrder to Supplier Solutions
    if (products.length) {
      let postData: PunchOutSubmit = {
        SessionId: this.sessionId,
        returnUrl: this.returnUrl,
        Timestamp: this.formatDate(),
        CartTotal: quote.totalLineAmount || 0,
        CartId: String(quote.orderNumber),
        CartProduct: [],
      };
      products.forEach((item, i) => {
        const personalCode =
          item.personalCodes && this.quickListHeaderId && item.personalCodes[this.quickListHeaderId]
            ? item.personalCodes[this.quickListHeaderId]
            : item.manufacturerPartNo || item.itemId;

        postData.CartProduct!.push({
          Line: item.sequence,
          PartNumber: item.itemId,
          Description: item.title,
          Qty: item.qty,
          NetSalePrice: item.price,
          UOMExt: item.uom,
          UNSPSC: item.unspsc,
          ManufacturerName: item.manufacturer,
          ManufacturerPartNumber: item.manufacturerPartNo,
          PersonalCode: personalCode,
          LeadTime: item.leadTime || null,
          QuoteNumber: String(quote.orderNumber),
        });
      });
      return postData;
    }
    return;
  }

  formatDate() {
    const currentDate = new Date();
    return currentDate.toISOString().replace('Z', '');
  }
}
