<div class="container">
  <div class="card-body"><img class="" src="../../../assets/img/vallen-en-55.png" alt="">
    <div class="card-title">
      Yes, you are in the right place! <br>
      We are not. We are trying to get there soon.
    </div>
    <!--        <p class="card-text">Sorry for the inconvenience but we’re performing some maintenance at the moment.</p>-->
    <p class="card-test">This application is down for maintenance. To assist you in the meantime, contact your inside
      sales representative. If you’re unsure of those details, please
      <u><a href="https://app.smartsheet.com/b/form/58752ac70e584a47bdafefbcea337120" target="_blank">complete this form</a></u>
      and a representative will be in touch with you as soon as possible.</p>
  </div>
  <div class="bg-color"><img class="" src="../../../assets/img/plug-graphic.png" alt="Site under maintenance"></div>
</div>
