import { Pipe, PipeTransform } from '@angular/core';
import { OrderTypes } from 'src/app/modules/order/order-history/order-history.model';
import { orderTypes } from '../../../modules/order/order-history/order-history.model';

@Pipe({ name: 'orderType' })
export class OrderTypePipe implements PipeTransform {
  transform(value: string): string {
    const types = orderTypes;
    let orderType: string = 'n/a';
    types.forEach((type) => {
      if (value === type.label.toUpperCase()) {
        return (orderType = type.value);
      } else {
        return orderType;
      }
    });
    return orderType;
  }
}
